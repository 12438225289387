import React from 'react'
import errorBG from '../../images/404-BG.png';

export default function Error() {
    
    return(
        <>
          <div className="error-page">s
              <img src={errorBG} alt='error-page' />
          </div>
        </>
    );
};
