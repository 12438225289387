import React from 'react'
// import HeaderPage from '../components/header/HeaderPage';
import {MdEmail} from 'react-icons/md';
import {BiMap} from 'react-icons/bi';
// import {BsFillTelephoneFill} from 'react-icons/bs';
import {FaFacebookF} from 'react-icons/fa';
import {RiInstagramFill} from 'react-icons/ri';




export default function Contact() {

    return(
        <>
         <div className="pre-header-bottom"></div>
         <div className="Header-page">
         <img src={"Assets/shape-2.png"} style={{'position':'absolute','height':'11em','marginTop':'23px'}} alt='#' />
         <img src={"Assets/shape-3.png"} style={{'position':'absolute','height':'13em','opacity':'0.1','right':'0'}} alt='#' />
             <div className="header-page-infos">
                <h2>CONTACTEZ NOUS</h2>
                <h3><a href="/">Home </a>/ <span>Contactez-nous</span></h3>
             </div>
         </div>
         <div className="contact-section">
          <div className="contact-col contact-infos">
               <h1> Entrer en contact </h1>
               <h3>Vous voulez entrer en contact ?<br/>Nous aimerions recevoir de vos nouvelles. </h3>
               <ul>
                   <li><a href="mailto:contact@telmol.com"><MdEmail />contact@telmol.com</a></li>
                   <li><a href="https://www.google.com/maps/place/6+Rue+d'Armaill%C3%A9,+75017+Paris,+France/@48.877455,2.2919706,3a,75y,158.46h,91.84t/data=!3m7!1e1!3m5!1swKUUrKWTqLw1ZKQqAEwxfQ!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DwKUUrKWTqLw1ZKQqAEwxfQ%26cb_client%3Dsearch.gws-prod.gps%26w%3D360%26h%3D120%26yaw%3D76.7825%26pitch%3D0%26thumbfov%3D100!7i16384!8i8192!4m5!3m4!1s0x47e66f92d2a43caf:0x916c93ae06a62893!8m2!3d48.8774828!4d2.2921401"><BiMap />6 Rue d'armaillé,75017,Paris</a></li>
                   <li><a href="https://www.facebook.com/telmolfb"><FaFacebookF />telmolfb</a></li>
                   <li><a href="https://www.instagram.com/telmolig"><RiInstagramFill />telmolig</a></li>
               </ul>
          </div>
          <div className="contact-col contact-form">
          <h2>Vous avez des questions ?</h2>
          <h6>Utilisez le formulaire ci-dessous pour entrer en contact avec l'équipe commerciale .</h6>
              <form>
                  <div className="row">
                  <input type="text" placeholder="  Prénom*" />
                  <input type="text" placeholder="  Nom*" />
                  </div>
                  <input type="text" placeholder="  Email*" />
                  <input type="text" placeholder="  Sujet*"/>
                  <input type="text" placeholder="  Message*" style={{'height':'45px'}} />
                  <button>ENVOYER</button>
              </form>
          </div>
         </div>
        </>
    );
};
