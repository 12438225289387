import React from 'react'
import { SlateEditorPreview } from '../helpers/slateEditor';
import {useParams} from "react-router-dom" ;
import data from '../../dataPosts.json';

export default function BlogDetails(){

    const {postId} = useParams();
    const thisPost = data.find(post => post.id == postId) ;

    return(
        <>

         <div className="pre-header-bottom"></div>
         <div className="Header-page">
         <img src={"Assets/shape-2.png"} style={{'position':'absolute','height':'11em','marginTop':'23px'}} alt='#' />
         <img src={"Assets/shape-3.png"} style={{'position':'absolute','height':'13em','opacity':'0.1','right':'0'}} alt='#' />
             <div className="blog-details-infos">
                <h6>{thisPost?.author} | {thisPost?.date}</h6>
                <h2>{thisPost?.title}</h2>
             </div>
             {/* <img src={"Assets/shape-3.png"} style={{'position':'absolute','height':'13em','top':'10px'}} alt='#' /> */}
             <div>
             </div>
         </div>
         <div className='blog-details-content'>
             <div className='blog-details-img'>
                   <img src={thisPost?.image} alt="img"  />
             </div>
             <div className='blog-details-text'>
                   <div className='blog-details-after'></div>
                  <SlateEditorPreview  value={thisPost?.contentFr}  />
             </div >

         </div>
        </>
    );
}