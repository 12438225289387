import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} textColor="inherit" indicatorColor="primary"  aria-label="basic tabs example" >
          <Tab label="Propriétés" {...a11yProps(0)}  />
          <Tab label="Qualité" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <>       <>
                <p>🌵 MEILLEUR SOIN ANTI-ÂGE, ANTI-CERNES et ANTI-RIDES | Sa teneur exceptionnelle en vitamine E, en Oméga-6 et en stérols permet d'assouplir votre peau en favorisant son processus naturel de renouvellement cellulaire. Appliquer quotidiennement quelques gouttes en soin de nuit pour redonner fermeté et tonicité à votre peau. Attendez au moins un cycle de renouvellement cutané (28 jours) pour observer les résultats.</p>
                </>
                <>
                <p>🌵 EXCELLENT EMOLLIENT | Riche en stérols et en oméga-3 et 6, l’huile de graines de figue de barbarie hydrate la peau et les cheveux secs et régule le sébum des peaux grasses. Elle est idéale pour nourrir et hydrater les peaux jeunes et les peaux matures. Grâce à sa texture peu grasse (huile sèche) elle pénètre facilement l'épiderme en laissant la peau souple et nourrie. De plus, elle affine le grain de peau et resserre les pores dilatés.</p>
                </>
                <>
                
                <p>🌵 CICATRICES ET VERGETURES | L’huile de pépins de figue de barbarie a une action efficace pour gommer les cicatrices et les vergetures ce qui la rend Idéale pour la femme enceinte. Elle atténue également les lésions dues à l'eczéma et les traces de boutons d’acné. Par ailleurs, elle unifie le teint, efface les taches de vieillesse et les cernes en activant la microcirculation locale.</p>
                </>
        </>        
      </TabPanel>
      <TabPanel value={value} index={1}>
              <>
              <p>🌵 TELMOL - LE MEILLEUR POUR VOTRE PEAU | Toutes nos huiles sont issues de l’agriculture biologique, extraites par première pression à froid pour conserver toutes les vertus du fruit. Les pepins utilisés font l'objet d'une sélection stricte et l’ensemble de nos huiles passe par des tests rigoureux en laboratoire afin de vous proposer la meilleure qualité possible et nous assurer que nos produits ne constituent absolument aucun danger pour vous.  </p>
              </>
      </TabPanel>
    </Box>
  );
}
