import React from 'react' ;
import BlogContainer from '../BlogConatiner';
import ClientSayConatiner from '../ClientSaycontainer';
import HeaderBottom from '../common/header/HeaderBottom';
import ProductContainer from '../ProductContainer';
// import InstagramContainer from '../components/InstagramContainer';



export default function Acueil() {

    return(
        <>
            <HeaderBottom />
            <ProductContainer />
            <ClientSayConatiner />
            <BlogContainer />
            {/* <InstagramContainer /> */}
        </>
    );
};
