import React from 'react';
import icn1 from '../../../images/footer/herb-copy.png';
import icn2 from '../../../images/footer/pure-water.png';
import icn3 from '../../../images/footer/rabbit.png';
import icn4 from '../../../images/footer/CHECK.jpg';


export default function PreFooter() {

    return(
        <>
        {/* product Section  */}
          <div className="pre-footer">
          <img src={"Assets/bgshape-8.png"} style={{'position':'relative','height':'4em','left':'-5%'}} alt='#' />
         
               <ul>
                 <li><img src={icn1} alt="icn1"/><span>Bio</span></li>
                 <li><img src={icn2} alt="icn1"/><span>Vegan</span></li>
                 <li><img src={icn3} alt="icn1"/><span>Sans cruauté</span></li>
                 <li><img src={icn4} alt="icn1"/><span>Sourcing éthique</span></li>
               </ul>
          <img src={"Assets/bgshape-4.png"} style={{'position':'absolute','height':'5em','right':'0'}} alt='#' />
          </div>
        </>
    ) ;

};
