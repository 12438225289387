import React from 'react'
// import PostCard from './PostCard';

import {MdOutlineDateRange} from 'react-icons/md';
import { Link } from 'react-router-dom';
// import img1 from '../images/blog/old-woman.jpg';

import data from '../dataPosts.json';

export default function BlogContainer(){


    return(
        <>
        <h2 className="titile-section titile-section-blog">ASTUCES BEAUTÉ</h2>
        <div className="blog-container">

         {
             data.map((dt,index) =>
             <div className="Post-card">
                 <Link to={`blogdetails/${dt.id}`} >
                    <div className="post-img">
                        <img src={dt.image} alt="img" />
                    </div>
                    <div className="post-details">
                        <h5><MdOutlineDateRange />{dt.date}</h5>
                        <h4>{dt.title}</h4>
                        <p>{dt.content}</p>
                    </div>
                  </Link>
              </div>
             )
         }
        {/* <PostCard /> */}

        </div>
        </>
    );
}