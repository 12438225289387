import React from 'react' ;

export default function FooterCopyright(){

    return(
        <>
        {/* Footer section */}
        <div className="footer-copyright">
            <p style={{'marginBottom':'0rem'}}><span>© 2021 FCPO </span>All Rights Reserved.</p>
        </div>
        </>
    );

}