import { Component } from 'react';
import ImageGallery from 'react-image-gallery';


const images = [
    {
        original: '/Assets/product/MainImage8k.png',
        thumbnail: '/Assets/product/MainImage8k.png',
        },
  {
    original: '/Assets/product/telmol1.jpg',
    thumbnail: '/Assets/product/telmol1.jpg',
  },
  
  {
    original: '/Assets/product/telmol2.jpg',
    thumbnail: '/Assets/product/telmol2.jpg',
  },
  {
    original: '/Assets/product/telmol3.jpg',
    thumbnail: '/Assets/product/telmol3.jpg',
  },
  {
    original: '/Assets/product/telmol4.jpg',
    thumbnail: '/Assets/product/telmol4.jpg',
  },
  {
    original: '/Assets/product/telmol5.jpg',
    thumbnail: '/Assets/product/telmol5.jpg',
  },
  {
    original: '/Assets/product/telmol6.jpg',
    thumbnail: '/Assets/product/telmol6.jpg',
  }
  
];

export default class MyGallery extends Component {
  render() {
    return <ImageGallery items={images} />;

  }
}