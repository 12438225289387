import React from 'react';
// import ProductContainer from '../components/ProductContainer';
import {AiFillStar} from 'react-icons/ai' ;
import TabPanel from '../TabPanel' ;
import MyGallery from '../features/ReactImageGallery';



export default function Product() {

    return(
        <>
           <div className="pre-header-bottom"></div>
            {/* <div className="header-top">
                <h6 className="pub-header" style={{'fontSize':'12px','margin':'auto','padding':'8px'}}> EN CADEAU : Un mini soin lavant offert pour 75€ d'achat  </h6>
            </div> */}

           <div className="product-page">
               <section >
                    <MyGallery />
               </section>

               <section className="product-page-infos">
                   <h3>HUILE DE PEPIN DE FIGUE DE BARBARIE BIO | 100% Pure, première pression à froid | 30 </h3>
                   <h6 style={{'display':'flex','flexDirection':'column'}}><span><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar />  </span>  (18 reviews)</h6>
                   <h2>39.9€ </h2>
                   <p>Véritable miracle de la nature, l’huile précieuse de graines de figue de barbarie est le meilleur allié de la peau contre les effets de l’âge. Sa teneur exceptionnelle en Vitamine E en Oméga-6 et en stérols permet de ralentir le vieillissement cutané en favorisant le processus naturel de renouvellement cellulaire de votre peau .</p>
                   <a href="https://www.amazon.fr/dp/B09JN4V4FB?maas=maas_adg_44CA0AB8227C2D05A60CF119867DC8A1_afap_abs&ref_=aa_maas&tag=maas" >ACHETER MAINTENANT</a>
               </section>
           </div>
           <div className="product-page-desc">
           <TabPanel />
           </div>

           <div>
               {/* <MyGallery /> */}
           </div>
        </>
    );
}