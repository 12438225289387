import React from 'react'
import {MdOutlineDateRange} from 'react-icons/md';
import data from '../../dataPosts.json';
import { Link } from 'react-router-dom';

export default function Blog() {
 

    return(
        <>
        {/* product Section  */}
        <div className="pre-header-bottom"></div>
         <div className="Header-page">
         <img src={"Assets/shape-2.png"} style={{'position':'absolute','height':'11em','marginTop':'23px'}} alt='#' />
         <img src={"Assets/shape-3.png"} style={{'position':'absolute','height':'13em','opacity':'0.1','right':'0'}} alt='#' />
             <div className="header-page-infos">
                <h2>ASTUCES BEAUTÉ</h2>
                <h3><a href="/">Home </a>/ <span>Astuces beauté</span></h3>
             </div>
         </div>
        
        <h2 className="titile-section">DERNIER ARTICLE</h2>

        {
            data.slice(0,1).map((dt) => 
          <div className="blog-page-latest">
            <section className="blog-latest-infos">
              <h6><MdOutlineDateRange />  {dt.date}</h6>
              <h3>{dt.title}</h3>
              <p>
              {dt.content}
              </p>
              <Link to={`blogdetails/${dt.id}`} >Lire la suite</Link>
            </section>
            <section className="blog-latest-img">
             <img src={dt.image} alt="img-latest" />
            </section>
        </div>)
}

         <h2 className="titile-section">BLOGS</h2>
        <div className="blog-container">
        {
             data.map((dt,index) =>
             <div className="Post-card">
                 <Link to={`blogdetails/${dt.id}`} >
                    <div className="post-img">
                        <img src={dt.image} alt="img" />
                    </div>
                    <div className="post-details">
                        <h5><MdOutlineDateRange />{dt.date}</h5>
                        <h4>{dt.title}</h4>
                        <p>{dt.content}</p>
                    </div>
                  </Link>
              </div>
             )
         }
        </div>
        </>
    ) ;

};
