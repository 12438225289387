import React from 'react'
import ProductCard from './ProductCard';

export default function ProductContainer() {

    return(
        <>
        {/* product Section  */}
        <h1 className="titile-section">NOS PRODUITS</h1>
          <div className="product-container product-after">
          {/* <img src={"Assets/bgshape-8.png"} style={{'position':'relative','height':'4em','left':'-5%'}} alt='#' /> */}
                <ProductCard />
          </div>
        </>
    ) ;

};
