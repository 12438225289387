import React from 'react' ;
// import { Link } from 'react-router-dom';

import imgSlide1 from "../../../images/slide1.jpg" ;
import imgSlide2 from '../../../images/slide2.jpg' ;




export default function HeaderBottom(){
    return(
        <>
           <div className="pre-header-bottom"></div>
            {/* <div className="header-top">
                <h6 className="pub-header" style={{'fontSize':'12px','margin':'auto','padding':'10px'}}> EN CADEAU : Un mini soin lavant offert pour 75€ d'achat </h6>
            </div> */}
           <div className="header-bottom container">
                            {/* <div className="header-product-infos">
                                <span className="header-title-product">Naturel-Biologique-Eco responsable</span>
                                <span className="header-details-product">Des produits de qualité, fabriqués dans le respect de la terre et du vivant</span>
                                <span className="header-price-product">39.9€</span>
                                <button className="buy-link"><Link to="/VegetableOil" style={{'textDecoration':'none','color':'black'}}> ACHETEZ MAINTENANT</Link></button>
                            </div> */}
                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                            <ol className="carousel-indicators">
                                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            </ol>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                <img className="d-block w-100" src={imgSlide2} alt="First slide" />
                                </div>
                                <div className="carousel-item">
                                <img className="d-block w-100" src={imgSlide1} alt="Second slide" />
                                </div>
                            </div>
                            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>

           </div>
        </>
    );

}