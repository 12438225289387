import React from 'react' ;
// import HeaderBottom from './HeaderBottom';
import HeaderMiddle from './HeaderMiddle';
// import HeaderTop from './HeaderTop';

import {AiOutlineInstagram} from 'react-icons/ai';
import {AiOutlineFacebook} from 'react-icons/ai';
import imgFR from '../../../images/franceImg.png' ;


export default function Header(){

    return(
        <>
           <div className="header-navbar">
           <div className="header-top">

           <div className="social-icons">
               <a href="https://www.instagram.com/telmolig"><AiOutlineInstagram /></a>
               <a href="https://www.facebook.com/telmolfb"><AiOutlineFacebook /></a>
            </div>
            <h6 className="pub-header" style={{'fontSize':'12px'}}> </h6>
                <div className="switch-lang">
                <div><img src={imgFR} alt="en" /></div>
                    {/* <select id="lg">

                        </select> */}
                    </div>
            </div>

           <HeaderMiddle />

           {/* <div className="header-top">
            <h6 className="pub-header" style={{'fontSize':'12px','margin':'auto','padding':'8px'}}> EN CADEAU : Un mini soin lavant offert pour 75€ d'achat </h6>
            </div> */}

           </div>
        </>
    );

}