import React from 'react'
import ClientSay from './ClientSay';


export default function ClientSayConatiner(){

    return(
        <>
           <div className='container'>

        
            {/* Client say about us section  */}
            {/* <img src={"Assets/bgshape-1.png"}  style={{'position':'absolute','height':'13em','opacity':'0.1','right':'0'}}  alt='#' /> */}
            <div className="pre-clientSay-conatiner">
            <img src={"Assets/shape-face.png"} className='pre-clientSay-icon-right'  alt='#' />
            <h1 className="titile-section-icon" >CE QUE DISENT NOS CLIENTS</h1>
            <div className="clientSay-conatiner">
                <ClientSay />
            </div>
            {/* <img src={"Assets/bgshape-3.png"} className='pre-clientSay-icon-left'  alt='#' /> */}
            </div>
            </div>
        </>
    );
}