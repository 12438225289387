import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../images/TELMOL-Logo-transparent-01.png';
import {FaBars , FaTimes } from 'react-icons/fa';
import {useState} from 'react' ;

// import {HiOutlineShoppingCart} from 'react-icons/hi';


export default function HeaderMiddle() {
    const [click, setClick] = useState()
    const clickHandler = () =>{setClick(!click);};
    return(
    <>
    <div className="logo-icons">
              <a href=" "> <img src={logo} className="img-fluid" alt="logo"/> </a>
           </div>
       <div className="header-middle">
           
           <div className="header-menu" >
               <ul className={click ? "nav-list active" : "nav-list"}>
                   <li><Link to="/">Accueil</Link></li>
                   <li><Link to="/vegetableoil">Huile végétale</Link></li>
                   <li><Link to="/apropos">Telmol</Link></li>
                   <li><Link to="/blog">Astuces beauté</Link></li>
                   <li><Link to="/contact">Contactez-nous</Link></li>
                   <li></li>
               </ul>
           </div>
           <div onClick={clickHandler} className="hamburger">{click ? <FaTimes />  : <FaBars />}</div>
        {/* <div className="shop-area">
            <div className="searsh-header-icons">

            </div>
            <div className="shop-header-icon">
                <span className="shop-icon"><HiOutlineShoppingCart /></span>
                <div className="shop-counter"><span>0</span></div>
            </div>
            <div className="price-header-icon">
                <h6>0 <span> EUR</span></h6>
            </div>

        </div> */}

          
       </div>
    </>
    );
};
