import React from 'react';


export default function About() {

    return(
        <>
         <div className="pre-header-bottom"></div>
         {/* <div className="Header-page">
         <img src={"Assets/shape-2.png"} style={{'position':'absolute','height':'11em','marginTop':'23px'}} alt='#' />
         <img src={"Assets/shape-3.png"} style={{'position':'absolute','height':'13em','opacity':'0.1','right':'0'}} alt='#' />
             <div className="header-page-infos">
                <h2>TELMOL</h2>
                <h3><a href="/">Home </a>/ <span>A propos</span></h3>
             </div>
         </div> */}
         <div className="container">
          {/* <div className="about-infos">
           <section className="about-card">
                <div>
                    <img src={img1} alt="" />
                </div>
                <div className="about-card-details">
                    <h3>Notre objectif</h3>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                </div>
           </section>
           <section className="about-card">
                <div>
                    <img src={img2} alt="" />
                </div>
                <div className="about-card-details">
                    <h3>Notre entreprise</h3>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit , sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                </div>
           </section>
          </div> */}
          
                <div className='about-section-img'> 
                    <img src="Assets/about.jpg" alt="aboutImg" style={{'width':'100%'}} />
                </div>
                {/* <section className="about-det">
                    <h2>À propos</h2>
                    <h4>
                    Telmol est le fruit d’une ambition : Rassembler au même endroit, le meilleur de ce qu’a la nature à offrir à votre peau. Découvrez les valeurs qui nous guident pour vous offrir des huiles végétales naturelles, issues d’une agriculture biologique qui impacte positivement celles et ceux qui prennent part à son développement.
                    </h4>
                </section> */}
           

          {/* <div className="about-details">
              <section className="about-details-infos">
                  <h1 className='about-title-section'>Le meilleur pour votre peau</h1>
                  <p>Votre peau est en contact permanent avec l’extérieur, une barrière naturelle qui lutte contre les irritations du quotidien (stress, pollution, etc.) et qui est sensible aux attentions que vous lui portez. Il est donc important de lui prodiguer un soin adapté.</p>
                   <p> Chez Telmol, nous mettons tout en œuvre pour vous offrir ce qu’il y a de mieux pour votre peau. Nous vous proposons des produits de haute qualité qui correspondent à vos besoins dans une gamme diversifiée qui s’adapte à tous types de peau. </p>
                   <p> Toutes nos huiles sont <strong>issues d’une agriculture biologique</strong>  et extraites par <strong>première pression à froid</strong> pour conserver toutes les vertus du fruit. Les graines utilisées font l'objet d'une <strong>sélection stricte </strong>et l’ensemble de nos huiles passe par des <strong>tests rigoureux en laboratoire</strong> afin de vous proposer la meilleure qualité possible et nous assurer que nos produits ne constituent absolument aucun danger pour vous. Vous n’y trouverez par ailleurs, jamais de composants chimiques de synthèse ni de molécules nocives connues ou non.
                    </p>
                    <div className="about-details-icons">
                        <div>
                            <img src={about1} alt="about1" />
                            <h3>AGRICULTURE BIOLOGIQUE</h3>
                        </div>    
                        <div>
                            <img src={about2} alt="about1" />
                            <h3>SELECTION STRICTE DES GRAINES</h3>
                        </div>   
                        <div>
                            <img src={about3} alt="about3" />
                            <h3>PREMIÈRE PRESSION À FROID</h3>
                        </div>   
                        <div>
                            <img src={about4} alt="about4" />
                            <h3>100 % PURE</h3>
                        </div>  
                        <div>
                            <img src={about5} alt="about5" />
                            <h3>TESTÉE EN LABORATOIRE</h3>
                        </div>    
                           
                    </div>
                    </section>
                    <section className="about-details-infos">
                  <h1 className='about-title-section'>Traditionnelles & contemporaines</h1>
                  <p>Les huiles végétales proviennent d’un savoir-faire ancestral. Les premiers moulins qui attestent la fabrication d’huile végétale sont apparus en Asie-mineure vers 6000 avant notre ère. Elles ont été beaucoup utilisées par les pharaons en Égypte, notamment par les reines égyptiennes pour leurs propriétés cosmétiques. Cette tradition s’étendit rapidement à toute la Méditerranée et se perpétua au fil des siècles.</p>
                  <p>  Nos huiles végétales lient le passé et le présent avec une touche d’innovation. Nous mettons un point d’honneur à sélectionner les meilleurs ingrédients pour répondre aux besoins de votre peau tout en gardant les valeurs premières des routines de beauté d’antan. 
                    </p>
                    <div className="about-details-icons">
                        <div>
                            <img src={about6} alt="about1" />
                            <h3>AGRICULTURE BIOLOGIQUE</h3>
                        </div>    
                    </div>
                    </section>
                    <section className="about-details-infos">
                  <h1 className='about-title-section'>Respectueuses du vivant</h1>
                  <p>Contrôler la provenance de nos produits est primordial pour nous. Nous suivons avec attention toute la chaîne de production de la conception jusqu’à la mise en flacon. Nos huiles proviennent de coopératives quasi exclusivement féminines du sud du Maroc et nous collaborons uniquement avec des partenaires qui partagent les mêmes valeurs que nous, garantissant des conditions de travail respectueuses et une rémunération juste pour ces femmes.
                      </p> <p>
                    Nous nous engageons également à favoriser la conservation du patrimoine naturel local. Nos huiles sont issues de plantes sauvages récoltées à la source en respectant à la lettre la charte de l’agriculture biologique, ce qui assure une qualité optimale et permet de préserver l’environnement naturel de ces plantes. </p>
                    <p>Le respect de notre environnement passe tout aussi bien par la flore que la faune, c’est pour cela que nos huiles ne sont pas testées sur les animaux et ne le seront jamais.  </p>
                    <p>S’engager pour notre planète concerne aussi les emballages. Nous avons donc fait le choix, chez Telmol de conditionner nos produits dans des emballages entièrement recyclables nous permettant de limiter notre empreinte écologique et favorisant l’apprentissage des bons gestes pour demain.

                    </p>
                    <div className="about-details-icons">
                        <div>
                            <img src={about7} alt="about1" />
                            <h3>AGRICULTURE BIOLOGIQUE</h3>
                        </div>
                        <div>
                            <img src={about8} alt="about1" />
                            <h3>SELECTION STRICTE DES GRAINES</h3>
                        </div>
                        <div>
                            <img src={about9} alt="about3" />
                            <h3>PREMIÈRE PRESSION À FROID</h3>
                        </div>
                    </div>
                    </section>
          </div> */}

         </div>
        </>
    );
};
