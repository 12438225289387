import React from 'react'



export default function ClientSay(){

    return(
        <>
           {/* client say card */}
            <div className="clientSay-card" tabIndex={1}>
                <p>
                Très hydratante et apaisante, pour le visage, elle a calmé ma peau sensible et l'a réhydratée. Je l'utilise aussi sur les pointes des cheveux, qu'elle laisse brillants mais pas gras. J'espère au fil du temps bénéficier de ses autres qualités annoncées : antitaches, anti-âge.
                </p>
                <h5>Jeanne.P</h5>
            </div>
           
            <div className="clientSay-card">
                <p>
                Depuis que j’ai découvert cette huile je n’utilise plus de crème de nuit. Cette huile est très efficace, en 4 mois, la peau de mon visage s'est redensifiée (50 ans) est plus souple, plus tonique donc les rides sont moins visibles.
                </p>
                <h5>Lara.B</h5>
            </div>
            <div className="clientSay-card">
                <p>
                Son utilisation simple, grâce à la pipette qui permet de prélever la juste quantité de produits sans excès. Ma peau l'absorbe très vite et est nourrit toute la journée. 
                </p>
                <h5>Alice.V</h5>
            </div>
        </>
    );
}