import React from 'react' ;
import FooterCopyright from './FooterCopyright';
import logoFooter from '../../../images/TELMOL-Logo-transparent-01.png';
import {AiOutlineInstagram} from 'react-icons/ai';
import {AiOutlineFacebook} from 'react-icons/ai';
import {HiOutlineChevronRight} from 'react-icons/hi';
export default function Footer(){

    return(
        <>
        {/* Footer section */}

        <div className="footer">
        <img src={"Assets/footer/shape-3.png"} style={{'position':'absolute','height':'20em','opacity':'0.1','left':'0'}} alt='#' />
        <img src={"Assets/footer/shape-2.png"} style={{'position':'absolute','height':'12em','marginTop':'84px','right':'0'}} alt='#' />
        <div className="footer-container" style={{'display':'flex','flexDirection':'column'}}>
            <img src={logoFooter} style={{'margin':'80px auto 20px','width':'180px'}} alt="img-logo-footer"/>
            <div  className="col1" style={{'display':'flex','alignItems':'flex-start','margin':'auto'}}>
            <div className="footer-usefullink footer-col">
               <h5 className="footer-title-section">LIENS UTILES</h5>
               <ul>
                   <li><a href="/vegetableoil">Huile végétale</a></li>
                   <li><a href="/apropos">Telmol</a></li>
                   <li><a href="/blog">Conseils de beauté</a></li>
                   <li><a href="/blog">Article de blog</a></li>
                   <li><a href="/contact">Contactez-nous</a></li>
               </ul>
            </div>
            <div className="footer-social footer-col">
            <h5 className="footer-title-section">NEWSLETTER</h5>
            <input type="text" placeholder="Enter your email" style={{'fontSize':'11px'}} /><button className="button-newsletter"><HiOutlineChevronRight /></button>
            <h5 className="footer-title-section" style={{'marginTop':'36px'}}>RESEAUX SOCIAUX</h5>
            <span><a style={{'fontSize':'30px','marginRight':'10px'}} href="https://www.instagram.com/"><AiOutlineInstagram /></a></span>
            <span><a style={{'fontSize':'30px'}} href="https://www.facebook.com/"><AiOutlineFacebook /></a></span>
            </div>
            </div>
            <div className="col2" style={{'display':'flex'}}>
            </div>
        </div>
       
        </div>
        <FooterCopyright />
        </>
    );

}