import React from 'react'
import { Link } from 'react-router-dom';
import produce1 from '../images/product/MainImage8k.png';
// import {HiOutlineShoppingCart} from 'react-icons/hi' ;
export default function ProductCard() {

    return(
        <>
        {/* product item */}
        
        <section className="product-section">
            <Link to="/VegetableOil" style={{'textDecoration':'none'}}>
                <div className="img-product">
                    {/* <div><HiOutlineShoppingCart /></div> */}
                    <img src={produce1} alt="" />
                </div>
             <div className="product-details">
                 <h6>Huile de Pépins de Barbarie Bio</h6>
                 <h5><span>39.9</span>€</h5>
             </div>
            </Link>
        </section>

        </>
    ) ;

};
