import './App.css';
import Footer from './components/common/footer/Footer';
import Header from './components/common/header/Header';
import PreFooter from './components/common/footer/PreFooter';
import { Routes } from 'react-router';
import Acueil from './components/pages/Acueil';
import {Route} from "react-router-dom" ;
import Contact from './components/pages/Contact';
import About from './components/pages/About';
import Product from './components/pages/Product';
import Blog from './components/pages/Blog';
import Error from './components/pages/Error';
import BlogDetails from './components/blog/BlogDetails';


function App() {
  return (
    <>
        <Header />
        <div className=''>
          <Routes>
             <Route  path='/' element={<Acueil />} />
             <Route path='/vegetableoil' element={<Product /> } />
             <Route path='/contact' element={<Contact />} />
             <Route path='/apropos' element={<About />} />
             <Route path='/blog' element={<Blog />} />
             <Route path='/blogdetails/:postId' element={<BlogDetails />} />
             <Route path='blog/blogdetails/:postId' element={<BlogDetails />} />
             <Route  element={<Error />}  />
          </Routes>
          </div>
        <PreFooter />
        <Footer />
    </>

  );
}

export default App;
